// FUNQTIONS ON DOCUMENT LOAD
jQuery(document).ready(function($) {
    /* declare language */
    var language = config.language;

    var pathArray = window.location.pathname.split('/');
    var page = pathArray[1];
    var slug = decodeURIComponent(pathArray[3]);

    /* moment init */
    if(language == 'ge') {
        language = 'ka';
    }
    moment.locale(language);

    var html = $('html');
    var body = $('body');
    var header = $('#header');
    var header_wrap = $('#header-wrap');
    var header_nav_box = $('#header-nav-box');
    var windowWidth = $(window).width();
    var backgroundBlock = $('#background-block');
    var widgetIcons = $('#widget-icons');
    var announcesWidget = $('#announces-icons');
    var homeSection = $('#home-section');
    var enrollWrap = $('#enroll-wrap');
    var portalSliderDescr = $('.portal-slide-descr');
    var news_card_title = $('.news-card-title');
    var album_card_title = $('.album-card-title');
    var wrap_home_fixeds = $('#wrap-home-fixeds');
    var home_spacer = $('#home-spacer');
    var searchResBox = $('#search-res-box');

    $(window).resize(function(event) {
        $(homeSection).css('height', $(window).height() - $(header).height() + 'px');
        if(windowWidth > 992) {
            $(enrollWrap).css('height', $(window).height() - $(header).height() + 'px');
            backgroundBlock.css('background-image', `url(${backgroundBlock.attr('data-image')}`);
        } else {
            (enrollWrap).css('height', 'auto');
            backgroundBlock.css('background-image', `url(${backgroundBlock.attr('data-mobile-image')}`);
        }
    }).resize();

    if(page) {
        header_nav_box.css('position', 'relative');
    } else {
        header_nav_box.css('position', 'absolute');

        if(windowWidth > 767) {
            wrap_home_fixeds.css('margin-top', $(header).height() + $(header_nav_box).height());
        } else {
            wrap_home_fixeds.css('margin-top', $(header).height());
        }
        home_spacer.css('height', wrap_home_fixeds.height());
        $(window).scroll(function () {
            // home scrolling parallax
            if ($(this).scrollTop() > $(homeSection).height() - (windowWidth > 767 ? $(header_wrap).height() : 0)) {
                header.addClass('sticky');
                home_spacer.hide();
                wrap_home_fixeds.addClass('scrolled');
                wrap_home_fixeds.css('margin-top', 0);
            } else {
                header.removeClass('sticky');
                home_spacer.show();
                wrap_home_fixeds.removeClass('scrolled');
                if(windowWidth > 767) {
                    wrap_home_fixeds.css('margin-top', $(header).height() + $(header_nav_box).height());
                } else {
                    wrap_home_fixeds.css('margin-top', $(header).height());
                }
            }
        });
    }

    if(windowWidth > 767) {
        searchResBox.css('padding-top', $(header).height() + $(header_nav_box).height());
    } else {
        searchResBox.css('padding-top', $(header).height());
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
            $('#scroll-to-top').fadeIn();
        } else {
            $('#scroll-to-top').fadeOut();
        }
    });
    
    /* search */
    var searchBtn = $('#search-btn');
    var searchInputBox = $('#search-input-box');
    var searchTransform = $('#search-transform');
    var searchField = $('#search-field');
    var searchFieldBtn = $('#search-field-btn');
    var searchedModule = $('.searched-module');
    var searchResults = $('#search-results');
    var searchItem = $('.search-results-item').find('a');
    var searchResultsList = $('#search-results-list');
    searchBtn.click(function(event) {
        $(this).find('i').toggle();
        body.toggleClass('overflow-hidden');

        setTimeout(function() {
            searchField.focus();
        }, 200);

        searchResBox.toggleClass('active');
    });

    searchFieldBtn.click(function(event) {
        if($(searchField).val().length > 2) {
            search($(searchField).val());
        } else {
            searchTransform.removeClass('active');
            searchResults.fadeOut();
        }
    });

    searchField.keyup(function(event) {
        if (event.keyCode == 27) {
            $(this).val('');
            searchTransform.removeClass('active');
            searchResults.fadeOut();
        } else if(event.keyCode == 13) {
            search($(searchField).val());
        }
    });

    searchedModule.click(function(event) {
        searchedModule.each(function(index, el) {
            $(this).removeClass('active');
        });
        $(this).addClass('active');
    });

    function search(term) {
        $.ajax({
            method: "POST",
            url: "/search_form/search",
            data: {
                search: term,
                lang: config.language
            }
        }).done(function(res) {
            if(res != 'error' && res != '') {
                response = jQuery.parseJSON(res);

                $('.searched-module.news').find('.searched-module-count').text(response.news.length);
                $('.searched-module.announces').find('.searched-module-count').text(response.announces.length);
                $('.searched-module.menu').find('.searched-module-count').text(response.menu.length);

                function fillSearchData(response) {
                    searchResultsList.empty();

                    let news = response.news;
                    let announces = response.announces;
                    let menu = response.menu;

                    if($('.searched-module.active').hasClass('news')) {
                        for (var i = news.length - 1; i >= 0; i--) {
                            let item = "<li class='search-results-item'>" +
                                            "<a href='/news/index/" + news[i].slug + "'>" + news[i].title + "</a>" +
                                        "</li>"
                            searchResultsList.append(item);
                        }
                    } else if($('.searched-module.active').hasClass('announces')) {
                        for (var i = announces.length - 1; i >= 0; i--) {
                            let item = "<li class='search-results-item'>" +
                                            "<a href='/announcements/slug/" + announces[i].slug + "'>" + announces[i].title + "</a>" +
                                        "</li>"
                            searchResultsList.append(item);
                        }
                    } else if ($('.searched-module.active').hasClass('menu')) {
                        for (var i = menu.length - 1; i >= 0; i--) {
                            let link = menu[i].type == '0' ? menu[i].link : "/menu/slug/" + menu[i].slug;
                            let target = menu[i].type == '0' ? '_blank' : '_self';
                            let item = "<li class='search-results-item'>" +
                                            "<a href='" + link + "' target='" + target + "'>" + menu[i].title + "</a>" +
                                        "</li>"
                            searchResultsList.append(item);
                        }
                    }

                    $('.search-results-item').find('a').hover(function() {
                        $(this).parent('li').addClass('hover');
                    }, function() {
                        $(this).parent('li').removeClass('hover');
                    });
                }

                fillSearchData(response);

                $('.searched-module').click(function(event) {
                    fillSearchData(response);
                });

                searchTransform.addClass('active');
                searchResults.fadeIn();
            }
        });
    }

    /* color change B/W */
    var headerColorChange = $('.header-color-changer');
    headerColorChange.click(function(event) {
        body.toggleClass('w-to-black');
        if($(this).hasClass('clicked')) {
            $(this).removeClass('clicked');
            Cookies.set('w-to-black', '0');
            $(this).find('img').attr('src', '/public/assets/images/b_w.png');
        } else {
            $(this).addClass('clicked');
            Cookies.set('w-to-black', '1');
            $(this).find('img').attr('src', '/public/assets/images/color-logo.png');
        }
    });

    if(Cookies.get('w-to-black') == '1') {
        headerColorChange.each(function(index, el) {
            $(this).addClass('clicked');
            $(this).find('img').attr('src', '/public/assets/images/color-logo.png');
        });
        body.toggleClass('w-to-black');
    }

    /* font size change */
    var fontSize = $('.header-font-resizer .fa');
    fontSize.click(function(event) {
        if($(this).hasClass('small')) {
            $('*').each(function(index, el) {
                if(!$(this).hasClass('nochange')) {
                    $(this).css('font-size', parseInt($(this).attr('data-normal-size')) - 2);
                }
            });
            Cookies.set('fontsize', 'small');
        } else if($(this).hasClass('default')) {
            $('*').each(function(index, el) {
                if(!$(this).hasClass('nochange')) {
                    $(this).css('font-size', parseInt($(this).attr('data-normal-size')));
                }
            });
            Cookies.set('fontsize', 'default');
        } else if($(this).hasClass('big')) {
            $('*').each(function(index, el) {
                if(!$(this).hasClass('nochange')) {
                    $(this).css('font-size', parseInt($(this).attr('data-normal-size')) + 2);
                }
            });
            Cookies.set('fontsize', 'big');
        }
        portalSliderDescr.shave(115);
        news_card_title.shave(70);
        album_card_title.shave(100);
    });

    $('*').each(function(index, el) {
        $(this).attr('data-normal-size', parseInt($(this).css('font-size')));
    });

    if(Cookies.get('fontsize')) {
        fontSize.each(function(index, el) {
            if($(this).hasClass(Cookies.get('fontsize'))) {
                $(this).trigger('click');
                portalSliderDescr.shave(115);
                news_card_title.shave(70);
                album_card_title.shave(100);
            }
        });
    }

    // side widget
    if(windowWidth > 767) {
        $('.widget-icons a').hover(function() {
            $(this).find('.widget-title').css('right', '0');
            $(this).prev('a').find('.widget-icon').addClass('borderno');
        }, function() {
            $(this).find('.widget-title').css('right', '-100%');
            $(this).prev('a').find('.widget-icon').removeClass('borderno');
        });

        $('.widget-icon-links').hover(function() {
            $(this).prev('.widget-title').css('right', '0');
        }, function() {
            $(this).prev('.widget-title').css('right', '-100%');
        });

        $('.fixed-quiz-toggler').hover(function() {
            $(this).prev('.widget-title').css('right', '0');
        }, function() {
            $(this).prev('.widget-title').css('right', '-100%');
        });

        $('.widget-title').hover(function() {
            $(this).css('right', '0');
        }, function() {
            $(this).css('right', '-100%');
        });
    }

    if(windowWidth < 767) {
        // element to detect scroll direction of
        var el = $(window),
        // initialize last scroll position
        lastY = el.scrollTop();
        el.on('scroll', function() {
            // get current scroll position
            var currY = el.scrollTop(),
            // determine current scroll direction
            y = (currY > lastY) ? 'down' : ((currY === lastY) ? 'none' : 'up');
            // do something here…
            if(y == 'down') {
                widgetIcons.removeClass('animate');
            } else if(y == 'up') {
                widgetIcons.addClass('animate');
            }
            // update last scroll position to current position
            lastY = currY;
        });

        $('#fixed-quiz-box').appendTo('body').addClass('centerV');
    }

    /* quiz */
    $('.widget-icons a.quiz #fixed-quiz-toggler').click(function(event) {
        $(this).prev('.widget-title').toggleClass('animate');
        $(this).find('i').toggle();
        $('#fixed-quiz-box').toggleClass('show');
        $('#fixed-quiz-question-box').toggleClass('show');
        if(windowWidth < 768) {
            body.toggleClass('overflow-hidden');
            $('#mobile-widget-dim').toggleClass('show');
        }
    });

    $(window).resize(function(event) {
        if($(window).width() > 767) {
            widgetIcons.css('top', (($(window).height() - widgetIcons.height()) / 2));
        } else {
            widgetIcons.css({
                top: 'auto'
            });
        }
    }).resize();

    $('.widget-icons a.quiz .widget-title').click(function(event) {
        $(this).toggleClass('animate');
        $(this).next('#fixed-quiz-toggler').find('i').toggle();
        $('#fixed-quiz-box').addClass('show');
        $('#fixed-quiz-question-box').addClass('show');
    });

    $('#mobile-widget-dim').click(function(event) {
        $('#fixed-quiz-toggler').click();
    });

    $('.fixed-quiz-answer-wrap').bind('click', function(event) {
        $('#fixed-quiz-answers').hide();
        $('#fixed-quiz-vote-counts').show();
    });

    $('input[name=poll]').click(function(e) {
        $(this).val(parseInt($(this).val())+1);
        var response_sum = parseInt($("#response_sum").val())+1;
        var selected_id = $(this).attr("data-id");
        var poll_id = $("#fixed-quiz-box").attr("data-id");
        $.ajax({
          method: "GET",
          url: "/Home/poll/" + selected_id + "/" + poll_id,
        }).done(function( msg ) {
            if(msg == 0) $(".fixed-quiz-box").remove();
            $('.fixed-quiz-vote-percent-box[data-id='+selected_id+']').addClass("chosen");
            $('input[name=poll]').each(function (e) {
                var data_id = $(this).attr("data-id");
                var prc = ($(this).val()*100/response_sum).toFixed(0);
                $('.fixed-quiz-vote-percent-box[data-id='+data_id+'] .uk-progress-bar').css("width",prc+'%');
                $('.fixed-quiz-vote-percent-box[data-id='+data_id+'] .uk-progress-bar span').html(prc+'%');
            });
        });
    });

    /* subscribe form */
    $('#subscribe-form-submit').click(function(event) {
        var email = $('#subscribe-form-email-field').val();
        var categories = [];
        $('.subscribe-form-checkbox-field').each(function(index, el) {
            if($(this).is(":checked")) {
                categories.push($(this).val());
            }
        });
        $.ajax({
            method: "POST",
            url: "/subscribe_form/subscribe",
            data: {
                email: email,
                categories: categories
            }
        }).done(function(res) {
            if(res == 'email_false') {
                $('.subscribe-form-fields-wrap .subscribe-email-error').show();
                $('.subscribe-form-fields-wrap .subscribe-email-success, .subscribe-form-fields-wrap .subscribe-email-exists').hide();
            } else if(res == 'success') {
                $('.subscribe-form-fields-wrap .subscribe-email-exists, .subscribe-form-fields-wrap .subscribe-email-error').hide();
                $('.subscribe-form-fields-wrap .subscribe-email-success').show(function() {
                    setTimeout(function () {
                        UIkit.modal("#subscribe-form-modal").hide();
                    }, 1000);
                });
            } else if(res == 'email_exists') {
                $('.subscribe-form-fields-wrap .subscribe-email-exists').show();
                $('.subscribe-form-fields-wrap .subscribe-email-success, .subscribe-form-fields-wrap .subscribe-email-error').hide();
            } else {
                $('.subscribe-form-fields-wrap .subscribe-email-error').show();
                $('.subscribe-form-fields-wrap .subscribe-email-success, .subscribe-form-fields-wrap .subscribe-email-exists').hide();
            }
        });
    });

    $('#footer-subscribe-btn').click(function(event) {
        var email = $('#subscribe').val();
        $.ajax({
            method: "POST",
            url: "/subscribe_form/subscribe",
            data: {
                email: email
            }
        }).done(function(res) {
            if(res == 'email_false') {
                $('.footer .subscribe-email-error').show();
                $('.footer .subscribe-email-success, .footer .subscribe-email-exists').hide();
            } else if(res == 'success') {
                $('.footer .subscribe-email-exists, .footer .subscribe-email-error').hide();
                $('.footer .subscribe-email-success').show();
            } else if(res == 'email_exists') {
                $('.footer .subscribe-email-exists').show();
                $('.footer .subscribe-email-success, .footer .subscribe-email-error').hide();
            }
        });
    });

    // contact form
    var sendMail = $('#contact-form-submit');
    var emailSuccess = $('#email-success');
    var emailError = $('#email-error');
    var hiddenCaptchaRes = $("#hiddenCaptchaRes");
    var contactName = $('#name');
    var contactSubject = $('#subject');
    var contactEmail = $('#email');
    var contactMessage = $('#message');
    var contactToEmail = $('#contact-to-email').attr('data-email');
    var copyEmailTo = $('#copy-to-email');

    sendMail.click(function(event) {
        if(contactEmail.val().length > 5
            && hiddenCaptchaRes.val().length > 0
            && contactName.val().length > 0
            && contactSubject.val().length > 0
            && contactMessage.val().length > 0) {

            emailError.hide();
            emailSuccess.hide();


            $.ajax({
                url: '/contact/send',
                type: 'POST',
                data: {
                    captchaResponse: hiddenCaptchaRes.val(),
                    name: contactName.val(),
                    subject: contactSubject.val(),
                    message: contactMessage.val(),
                    email: contactEmail.val(),
                    toEmail: contactToEmail,
                    copyEmailTo: copyEmailTo.is(':checked')
                }
            }).done(function(res) {
                grecaptcha.reset(contactRecaptcha);
                
                if(res) {
                    contactName.val('');
                    contactEmail.val('');
                    contactSubject.val('');
                    contactMessage.val('');
                    hiddenCaptchaRes.val('');
                    copyEmailTo.prop('checked', false);
                    
                    emailError.hide();
                    emailSuccess.show();

                    setTimeout(function() {
                        emailSuccess.hide();
                        $('.uk-modal-close').trigger('click');
                    }, 3000);
                } else {
                    emailError.show();
                    emailSuccess.hide();
                }
            });
        } else {
            emailError.show();
            emailSuccess.hide();
        }
    });

    /* partners carousel */
    $('#partners-carousel').slick({
        lazyLoad: 'ondemand',
        swipe: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: '#partners-carousel',
        prevArrow: "<div class='partners-nav left'><i class='uk-icon-chevron-left centerV'></i></div>",
        nextArrow: "<div class='partners-nav right'><i class='uk-icon-chevron-right centerV'></i></div>",
        responsive: [{
            breakpoint: 767,
            settings: {
                swipe: true,
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 479,
            settings: {
                swipe: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });

    /* news carousel */
    $('#news-carousel').slick({
        lazyload: true,
        swipe: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        appendArrows: '#news-carousel',
        prevArrow: "<div class='news-nav left centerV'><i class='uk-icon-chevron-left'></i></div>",
        nextArrow: "<div class='news-nav right centerV'><i class='uk-icon-chevron-right'></i></div>",
        responsive: [{
            breakpoint: 1370,
            settings: {
                swipe: true,
                slidesToShow: 4,
                slidesToScroll: 4
            }
        }, {
            breakpoint: 767,
            settings: {
                swipe: true,
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }, {
            breakpoint: 479,
            settings: {
                swipe: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
    portalSliderDescr.shave(115);

    /* news cards */
    var news_card = $('.news-card');
    var news_card_hover = $('.news-card-title, .news-card-img');
    news_card_hover.hover(function() {
        $(this).parents('.news-card').addClass('hover');
    }, function() {
        news_card.removeClass('hover');
    });
    news_card_title.shave(70);
    $('.news-card-date').each(function(index, el) {
        var oldDate = $(this).text();
        $(this).empty();
        $(this).append('<div>' + moment(oldDate, "YYYY-MM-DD").format('D') + '</div>');
        $(this).append('<div>' + moment(oldDate, "YYYY-MM-DD").format('MMM') + '</div>');
    });
    $('.news-date').each(function(index, el) {
        var oldDate = $(this).text();
        $(this).empty();
        $(this).append(moment(oldDate).format('D')+' ');
        $(this).append(moment(oldDate).format('MMMM')+', ');
        $(this).append(moment(oldDate).format('Y'));
    });

    $('.announces-icons-month').each(function(index, el) {
        let oldDate = $(this).text();
        $(this).text(moment(oldDate).format('MMM'));
    });

    /* personal cards */
    var personal_card = $('.personal-card');
    var personal_card_hover = $('.personal-card-title, .personal-card-img');
    personal_card_hover.hover(function() {
        $(this).parents('.personal-card').addClass('hover');
    }, function() {
        personal_card.removeClass('hover');
    });
    $('.personal-card-subtitle').shave(46);

    /* select2 init */
    $(".select2").select2({
        minimumResultsForSearch: Infinity
    });

    /* datepicker */
    if ($('#datepicker').length > 0) {
    	var datepickerLangs;
    	if(language == 'ru') {
    		datepickerLangs = {
                months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                weekdays: ['ВСК', 'ПНД', 'ВТР', 'СРД', 'ЧТВ', 'ПТН', 'СБТ']
            }
    	} else if(language == 'en') {
    		datepickerLangs = {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            }
    	} else {
    		datepickerLangs = {
                months: ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი'],
                weekdays: ['კვ', 'ორშ', 'სამ', 'ოთხ', 'ხუთ', 'პარ', 'შაბ']
            }
    	}
    	var datepicker = UIkit.datepicker($('#datepicker'), {
            format: 'YYYY-MM-DD',
            i18n: datepickerLangs,
            minDate: '2010-01-01',
            maxDate: (new Date()).getFullYear()+'-12-31',
            pos: 'auto'
        });
    }
    
    if($('#mini-clndr').length > 0) {
        $.ajax({
            type : 'POST',
            url : '/announcements/data',
            dataType: 'json',
            success : function(results) {
                // Go get event information and push into event array to display in calendar

                moment.locale(results[1]);

                var events = results[0];

                $('#mini-clndr').clndr({
                    template: $('#mini-clndr-template').html(),
                    events: events,
                    doneRendering: function (argument) {
                        $('.days-container .event span').addClass('calendar-day-row');
                    },
                    clickEvents: {
                        click: function(target) {
                            if(target.events.length) {
                                var daysContainer = $('#mini-clndr').find('.days-container');
                                daysContainer.toggleClass('show-events', true);
                                $('#mini-clndr').find('.x-button').click( function() {
                                daysContainer.toggleClass('show-events', false);
                              });
                            }
                            if(target.events.length == 0) 
                                return false;
                            var targetDate = target.events[0].date;
                            $("#mini-clndr .events .headers .event-header").html(moment(target.events[0].date).format('D MMMM'));

                            $("#mini-clndr .events-list .event").each(function() {
                                $(this).hide();
                            });
                            $("#mini-clndr .events-list ." + targetDate).each(function() {
                                $(this).show();
                            });
                        }
                    },
                    adjacentDaysChangeMonth: true,
                    forceSixRows: true
                });
            }
        }); // end ajax
    }
    /* toggle announces */
    $('#announce-changer').click(function(event) {
        $('#announces-list, .uk-icon-calendar, .uk-icon-list, #announces-calendar').toggle();
    });

    album_card_title.shave(100);

    // announcements - start date
    $('.announces-card-date').each(function(index, el) {
        var start_date = $(this).find('.start_date');
        var end_date = $(this).find('.end_date');
        var start_text = $(start_date).text();
        var end_text = $(end_date).text();

        $(start_date).empty();
        if($(end_date).text().length > 0) {
            $(end_date).empty();
            $(end_date).append(' - ' + moment(end_text).format('D MMMM'));
        }
        $(start_date).append(moment(start_text).format('D MMMM'));
    });

    // menu content images lightbox
    $('.article-wrap img, #print-area img').each(function(index, el) {
        $(this).wrap("<a href='" + $(this).attr('src') + "' data-uk-lightbox></a>");
    });

    /* select2 init */
    $(".select2").select2({
        minimumResultsForSearch: Infinity
    });

    // disable uikit slider loop
    $('#created_works').on('beforeshow.uk.slideshow', function(event, next_slide, cur_slide){
        if($('#created_works ul li:last-child').hasClass('uk-active')) {
            $('#created_works .portal-nav.right').hide();
        } else {
            $('#created_works .portal-nav.right').show();
        }

        if($('#created_works ul li:first-child').hasClass('uk-active')) {
            $('#created_works .portal-nav.left').hide();
        } else {
            $('#created_works .portal-nav.left').css('display', 'flex');
        }
    });

    $('#partner_organizations').on('beforeshow.uk.slideshow', function(event, next_slide, cur_slide){
        if($('#partner_organizations ul li:last-child').hasClass('uk-active')) {
            $('#partner_organizations .portal-nav.right').hide();
        } else {
            $('#partner_organizations .portal-nav.right').show();
        }

        if($('#partner_organizations ul li:first-child').hasClass('uk-active')) {
            $('#partner_organizations .portal-nav.left').hide();
        } else {
            $('#partner_organizations .portal-nav.left').css('display', 'flex');
        }
    });

    $('#graduate').on('beforeshow.uk.slideshow', function(event, next_slide, cur_slide){
        if($('#graduate ul li:last-child').hasClass('uk-active')) {
            $('#graduate .portal-nav.right').hide();
        } else {
            $('#graduate .portal-nav.right').show();
        }

        if($('#graduate ul li:first-child').hasClass('uk-active')) {
            $('#graduate .portal-nav.left').hide();
        } else {
            $('#graduate .portal-nav.left').css('display', 'flex');
        }
    });

    html.addClass('loaded');
    body.addClass('loaded');
});

// share on facebook
function fbShare() {
    var url = window.location.href;
    FB.ui({
        method: 'share',
        href: url
    });
}

// print div
function printDiv(elem) {
    var mywindow = window.open('', 'PRINT');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + ($('.text-page-title').text() ? $('.text-page-title').text() : $('.page-title.print-title').text() ? $('.page-title.print-title').text() : $('.page-title.parent').text())  + '</h1>');
    mywindow.document.write('<div>' + ($('#post-date').length > 0 ? $('#post-date').text() : '') + '</div>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}

// recaptcha callback
$(window).bind('load', function() {
    // contact form captcha
    if($('#contact-recaptcha').length) {
        var recaptchaCallback = function(response) {
            $('#hiddenCaptchaRes').val(response);
        };

        contactRecaptcha = grecaptcha.render(document.getElementById('contact-recaptcha'), {
            'sitekey' : '6Lewe1cUAAAAANj8yfJ3Weq1kixH4LLM0Qh8NcEu',
            'theme' : 'light',
            'callback': recaptchaCallback
        });
    }
});
