import '/public/assets/bower_components/font-awesome/css/font-awesome.min.css';
import '/public/assets/bower_components/uikit/css/uikit.min.css';
import '/public/assets/bower_components/uikit/css/components/slideshow.min.css';
import '/public/assets/bower_components/uikit/css/components/slider.min.css';
import '/public/assets/bower_components/uikit/css/components/slidenav.min.css';
import '/public/assets/bower_components/uikit/css/components/dotnav.min.css';
import '/public/assets/bower_components/uikit/css/components/tooltip.min.css';
import '/public/assets/bower_components/uikit/css/components/progress.min.css';
import '/public/assets/bower_components/uikit/css/components/form-select.min.css';
import '/public/assets/bower_components/uikit/css/components/datepicker.min.css';
import '/public/assets/bower_components/uikit/css/components/accordion.min.css';
import '/public/assets/bower_components/select2/dist/css/select2.min.css';
import '/public/assets/bower_components/slick-carousel/slick/slick.css';
import '/public/assets/css/style.css';
import '/public/assets/css/clndr.css';

import uikit from '/public/assets/bower_components/uikit/js/uikit.min.js';
import slideshow from '/public/assets/bower_components/uikit/js/components/slideshow.min.js';
import slideshowFx from '/public/assets/bower_components/uikit/js/components/slideshow-fx.min.js';
import touch from '/public/assets/bower_components/uikit/js/core/touch.min.js';
import slider from '/public/assets/bower_components/uikit/js/components/slider.min.js';
import lightbox from '/public/assets/bower_components/uikit/js/components/lightbox.min.js';
import tooltip from '/public/assets/bower_components/uikit/js/components/tooltip.min.js';
import formSelect from '/public/assets/bower_components/uikit/js/components/form-select.min.js';
import sticky from '/public/assets/bower_components/uikit/js/components/sticky.min.js';
import datepicker from '/public/assets/bower_components/uikit/js/components/datepicker.min.js';
import grid from '/public/assets/bower_components/uikit/js/components/grid.min.js';
import accordion from '/public/assets/bower_components/uikit/js/components/accordion.min.js';
import shave from '/public/assets/bower_components/shave/dist/shave.min.js';
import countUp from '/public/assets/bower_components/countUp.js/dist/countUp.min.js';
import mustache from '/public/assets/bower_components/mustache.js/mustache.min.js';

import * as scripts from '/public/assets/js/main.js';
